<script setup>
import {useState} from "nuxt/app";
import StudentProfilePicture from "../General/StudentProfilePicture";

definePageMeta({
  middleware: 'auth'
});

const { user, loggedIn } = useState('auth').value;

</script>

<template>
  <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
    <button @click="$emit('open-sidebar')" type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden">
      <span class="sr-only">Open sidebar</span>
      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"></path>
      </svg>
    </button>
    <div class="flex flex-1 justify-between px-4">
      <div class="flex flex-1">
<!--        <form class="flex w-full md:ml-0" action="#" method="GET">-->
<!--          <label for="search-field" class="sr-only">Search</label>-->
<!--          <div class="relative w-full text-gray-400 focus-within:text-gray-600">-->
<!--            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">-->
<!--              <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />-->
<!--            </div>-->
<!--            <input id="search-field" class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Search" type="search" name="search" />-->
<!--          </div>-->
<!--        </form>-->
      </div>
      <div class="ml-4 flex items-center md:ml-6">
<!--        <button type="button" class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">-->
<!--          <span class="sr-only">View notifications</span>-->
<!--          <BellIcon class="h-6 w-6" aria-hidden="true" />-->
<!--        </button>-->

        <!-- Profile dropdown -->
        <Menu as="div" class="relative ml-3">
          <div>
            <MenuButton class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
              <StudentProfilePicture :name="user.name"></StudentProfilePicture>
              <span class="sr-only">Open user menu</span>
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                <button @click="logout" :href="item.href" :class="[active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700']">{{ item.name }}</button>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
export default {
  name: "Header",
  components: {Menu, MenuItem, MenuItems, MenuButton, MagnifyingGlassIcon},
  data() {
    return {
      userNavigation: [
        // { name: 'Your Profile', href: '#' },
        // { name: 'Settings', href: '#' },
        { name: 'Sign out', href: '#' },
      ]
    }
  },

  methods: {
    async logout()
    {
      await this.$sanctumAuth.logout(
          // optional callback function
          (data) => {
            Intercom('shutdown');
            this.$router.push('/login')
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
